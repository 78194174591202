<template>
  <div>
    <c-tab
      :dense="true"
      type="pagetop"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      align="left"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'senior-dismissal-tab',
  data() {
    return {
      searchUrl: '',
      tab: 'status',
      tabItems: [
        { name: 'status', icon: 'list', label: 'LBL0010209', component: () => import(`${'./seniorDismissalStatus.vue'}`) }, //선해임 현황
        { name: 'master', icon: 'groups', label: 'LBL0010210', component: () => import(`${'./seniorDismissalMaster.vue'}`) }, //선해임명 관리
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>
